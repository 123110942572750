import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Agenda from '../components/accommodations/Agenda'
import PriceTable from '../components/accommodations/PriceTable'

import { CASA_BANGALO } from '../utils/available-accommodations'
import getBangaloData from '../messages/bangalo-messages'
import getCasaData from '../messages/casa-messages'

const AgendaAndPrices = () => {
    const casaData = getCasaData()
    const bangaloData = getBangaloData()

    return (
        <Layout>
            <Helmet
                title="Disponibilidades"
                meta={[
                    { name: 'description', content: 'Confira as disponibilidades e valores da adomodações da Casa do Sóter' },
                    { name: 'keywords', content: 'Lapinha da Serra, Lapinha, hospedagem, acomodação, acomodações, pousada, Serra do Cipó, Minas Gerais, turismo, viagem' },
                ]}
            />

            <div id="main">
                <Agenda accommodation={CASA_BANGALO} />
                <PriceTable accommodation={bangaloData} showAccommodationTitle/>
                <PriceTable accommodation={casaData} showAccommodationTitle/>
            </div>
        </Layout>
    )
}

export default AgendaAndPrices